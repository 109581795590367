import React, { useContext, useEffect, useState } from 'react';

import { BellOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';

import { GlobalContext } from '../context/GlobalContextProvider';
import './bell.css';

const Notifications = ({ style, iconStyle }: { style?: any; iconStyle?: any }): React.ReactElement => {
    const context = useContext(GlobalContext);
    const [showNotifications, setShowNotifications] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowNotifications(false);
        }, 3 * 1000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    useEffect(() => {
        if (context.plagarismReportsPromises.length) {
            Promise.all(context.plagarismReportsPromises)
                .then((resp: any) => {
                    console.log('response is', resp);
                    const allReports = [...context.plagarismReports];
                    const localNotifications = [];
                    for (const response of resp) {
                        const notificationMessage = `Plagarism check completed for ${response.data.name}`;

                        const existingReport = allReports.findIndex(report => {
                            return report.paper_id === response.data.paper_id;
                        });
                        if (existingReport !== -1) {
                            allReports[existingReport] = response.data;
                        }
                        context.setPlagarismReports(allReports);
                        if (!context.notifications.find((noti: any) => noti.message === notificationMessage)) {
                            localNotifications.push({
                                message: notificationMessage,
                                url: response.data.reportUrl,
                            });
                        }
                    }
                    context.setNotifications([...localNotifications, ...context.notifications]);
                })
                .catch(err => {
                    const allReports = [...context.plagarismReports];

                    const existingReport = allReports.findIndex(report => {
                        return report.paper_id === context.activePaper?._id;
                    });
                    if (existingReport !== -1) {
                        allReports[existingReport] = {
                            status: 'failed',
                        };
                    }
                    context.setPlagarismReports(allReports);
                });
        }
    }, [context.plagarismReportsPromises.length]);
    if (!context.notifications.length) {
        return <></>;
    }

    return (
        <div style={style}>
            {showNotifications ? (
                <Dropdown
                    visible={showNotifications}
                    overlay={
                        <>
                            <Menu theme="dark" style={{ background: '#1f1e37' }}>
                                {context.notifications.length &&
                                    context.notifications.map((notification: any) => {
                                        return (
                                            <Menu.Item key={notification.url}>
                                                <a href={notification.url} rel="noreferrer" target="_blank">
                                                    {notification.message}{' '}
                                                </a>
                                            </Menu.Item>
                                        );
                                    })}
                            </Menu>
                        </>
                    }
                >
                    <div className="notification">
                        <BellOutlined style={iconStyle} />
                        {context.notifications.length ? <div className="badge"></div> : null}
                    </div>
                </Dropdown>
            ) : (
                <Dropdown
                    overlay={
                        <>
                            <Menu theme="dark" style={{ background: '#1f1e37' }}>
                                {context.notifications.length &&
                                    context.notifications.map((notification: any) => {
                                        return (
                                            <Menu.Item key={notification.url}>
                                                <a href={notification.url} rel="noreferrer" target="_blank">
                                                    {notification.message}{' '}
                                                </a>
                                            </Menu.Item>
                                        );
                                    })}
                            </Menu>
                        </>
                    }
                >
                    <div className="notification">
                        <BellOutlined style={iconStyle} />
                        {context.notifications.length ? <div className="badge"></div> : null}
                    </div>
                </Dropdown>
            )}
        </div>
    );
};

export default Notifications;
